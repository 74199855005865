* {
  box-sizing: border-box;
}

html, body, #root, .App {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  overscroll-behavior: none;
}